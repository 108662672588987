.about{
  .about__wrapper{
    display: flex;
    .info-sec{
      width: 65%;
      .credit{
        letter-spacing: .04em;
        padding: 20px 0;
        border-bottom: 1px solid;
        border-color: rgba(255, 255, 255, 0.5);
        &:first-child{
          padding: 0px 0 20px 0;
        }
        dt{
          font-size: 1.2rem;
          margin-bottom: 10px;
        }
        dd{
          font-weight: 600;
          font-size: 1.6rem;
          line-height: 2.5rem;
          a{
            transition: all 1s ease;
            &:hover{
              filter: invert(100%);
            }
          }
        }
        .jp{
          font-size: 1.4rem;
          letter-spacing: .08em;
        }
      }
    }
    .title-sec{
      width: 35%;
      .title{
        letter-spacing: .04em;
        dt{
          font-size: 1.2rem;
          margin-bottom: 10px;
        }
        dd{
          font-size: 3rem;
          font-weight: 700;
          a{
            transition: all 1s ease;
            &:hover{
              filter: invert(100%);
            }
          }
        }
      }
    }
  }
}
