/* [ media query ]
-------------------------------------------------*/
$smp-small:  "max-width:320px";
$smp:  "max-width:750px";
$smp-lauge:  "max-width:750px and (min-width:400px)";
$tablet:  "min-width:751px";
$pc-small:  "max-width:1280px";
$pc-lauge:  "min-width:1601px";
$pc-all:  "min-width:751px";

/* [ color ]
-------------------------------------------------*/
$key-color:#fff;
$border-color:#d2d2d2;
$font-color:#272524;

/* [ Device size ]
-------------------------------------------------*/
$baseWidth  : 1600;
$baseHeight : 900;
$spBaseWidth  : 750;
$spBaseHeight : 1117;

/* [ font style ]
-------------------------------------------------*/
@mixin font-gothic{
	font-family: neue-haas-grotesk-display, helvetica, arial, sans-serif;
	font-style: normal;
	font-weight: 600;
  color: #fff;
	letter-spacing: 0em;
}
@mixin font-jp{
	font-family: a-otf-gothic-bbb-pr6n, sans-serif;
	font-style: normal;
	font-weight: 400;
	color: #fff;
	letter-spacing: 0em;
}
@mixin font-mintyou{
	font-family: YuMincho, '游明朝',"Times New Roman","Hiragino Mincho ProN","ヒラギノ明朝 Pro W3","HGS明朝B","ＭＳ Ｐ明朝",serif,"Meiryo","Sawarabi Mincho","メイリオ","ＭＳ Ｐゴシック",sans-serif;
}
@mixin font-en{
	font-family: 'Josefin Sans', serif;
	letter-spacing: 0.1em;
	font-weight: 500;
}
@mixin font-en-bold{
	font-family: 'Josefin Sans', serif;
	//letter-spacing: 0em;
	font-weight: 700;
}

:root {
	--z-header: 10000;
}

/* base_font-size 1rem = 10px */
html {
  font-size: 62.5%;
}

body {
	@include font-gothic();
  color: $key-color;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.jp{
	@include font-jp();
	text-align: justify;
	text-justify: inter-ideograph;
}

a{
  text-decoration: none;
  transition: all 0.3s ease;
	// &:hover{
	// 	filter: invert(100%);
	// }
	// &:before {
	// 	filter: invert(100%);
	// }
  &:link{
    color: $key-color;
  }
  &:visited{
    color: $key-color;
  }
}

img{
  display: block;
  width: 100%;
  height: auto;
}
