.works{
  .works__wrapper{
    margin-top: 10rem;
    display: flex;
    .img-sec{
      width: 65%;
      figure{
        margin-bottom: 2px;
      }
    }
    .info-sec{
      width: 35%;
      padding: 0  0 0 5rem;
      .title{
        letter-spacing: .04em;
        padding: 0px 0 20px 0;
        border-bottom: 1px solid;
        border-color: rgba(255, 255, 255, 0.5);
        dt{
          font-size: 1.2rem;
          margin-bottom: 10px;
        }
        dd{
          font-size: 3rem;
          font-weight: 700;
          a{
            transition: all 1s ease;
            &:hover{
              filter: invert(100%);
            }
          }
        }
      }
      .credit{
        letter-spacing: .04em;
        padding: 20px 0;
        border-bottom: 1px solid;
        border-color: rgba(255, 255, 255, 0.5);
        dt{
          font-size: 1.2rem;
          margin-bottom: 10px;
        }
        dd{
          font-weight: 700;
          font-size: 1.6rem;
          line-height: 2.5rem;
          a{
            transition: all 1s ease;
            &:hover{
              filter: invert(100%);
            }
          }
        }
        .jp{
          font-size: 1.3rem;
          letter-spacing: .08em;
        }
      }
    }
  }
}
