@charset "UTF-8";
/* [ reset ]
-------------------------------------------------*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
}

html {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

caption, th, td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle;
}

q, blockquote {
  quotes: none;
}

q:before, q:after, blockquote:before, blockquote:after {
  content: "";
  content: none;
}

a img {
  border: none;
}

img {
  vertical-align: bottom;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary {
  display: block;
}

*, *:before, *:after {
  box-sizing: border-box;
}

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat;
}

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal;
}

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}

.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}

.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}

[dir="rtl"] .slick-prev {
  left: auto;
  right: -25px;
}

.slick-prev:before {
  content: "←";
}

[dir="rtl"] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}

[dir="rtl"] .slick-next {
  left: -25px;
  right: auto;
}

.slick-next:before {
  content: "→";
}

[dir="rtl"] .slick-next:before {
  content: "←";
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}

.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}

.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* [ media query ]
-------------------------------------------------*/
/* [ color ]
-------------------------------------------------*/
/* [ Device size ]
-------------------------------------------------*/
/* [ font style ]
-------------------------------------------------*/
:root {
  --z-header: 10000;
}

/* base_font-size 1rem = 10px */
html {
  font-size: 62.5%;
}

body {
  font-family: neue-haas-grotesk-display, helvetica, arial, sans-serif;
  font-style: normal;
  font-weight: 600;
  color: #fff;
  letter-spacing: 0em;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.jp {
  font-family: a-otf-gothic-bbb-pr6n, sans-serif;
  font-style: normal;
  font-weight: 400;
  color: #fff;
  letter-spacing: 0em;
  text-align: justify;
  text-justify: inter-ideograph;
}

a {
  text-decoration: none;
  transition: all 0.3s ease;
}

a:link {
  color: #fff;
}

a:visited {
  color: #fff;
}

img {
  display: block;
  width: 100%;
  height: auto;
}

.about .about__wrapper {
  display: flex;
}

.about .about__wrapper .info-sec {
  width: 65%;
}

.about .about__wrapper .info-sec .credit {
  letter-spacing: .04em;
  padding: 20px 0;
  border-bottom: 1px solid;
  border-color: rgba(255, 255, 255, 0.5);
}

.about .about__wrapper .info-sec .credit:first-child {
  padding: 0px 0 20px 0;
}

.about .about__wrapper .info-sec .credit dt {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.about .about__wrapper .info-sec .credit dd {
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 2.5rem;
}

.about .about__wrapper .info-sec .credit dd a {
  transition: all 1s ease;
}

.about .about__wrapper .info-sec .credit dd a:hover {
  filter: invert(100%);
}

.about .about__wrapper .info-sec .credit .jp {
  font-size: 1.4rem;
  letter-spacing: .08em;
}

.about .about__wrapper .title-sec {
  width: 35%;
}

.about .about__wrapper .title-sec .title {
  letter-spacing: .04em;
}

.about .about__wrapper .title-sec .title dt {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.about .about__wrapper .title-sec .title dd {
  font-size: 3rem;
  font-weight: 700;
}

.about .about__wrapper .title-sec .title dd a {
  transition: all 1s ease;
}

.about .about__wrapper .title-sec .title dd a:hover {
  filter: invert(100%);
}

/*----------------------------------------------------
  smp
----------------------------------------------------*/
@media screen and (max-width: 750px) {
  header {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: var(--z-header);
  }
  header .header__wrapper {
    padding: calc(1rem + 5vw) 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  header .header__wrapper .logo {
    width: 38%;
    max-width: 300px;
    min-width: 100px;
    height: auto;
  }
  header .header__wrapper .menu {
    letter-spacing: .04em;
    font-size: 1.4rem;
    transition: all 0.8s ease;
  }
  header .header__wrapper .menu ul {
    display: flex;
  }
  header .header__wrapper .menu li {
    margin-left: 40px;
  }
  header .header__wrapper .menu li:before {
    content: "";
    position: relative;
    top: -1px;
    left: -10px;
    display: inline-block;
    background-size: contain;
    vertical-align: middle;
    background-color: #fff;
    width: 5px;
    height: 5px;
    border-radius: 50%;
  }
  .marquee {
    margin-top: 6em;
    width: 100%;
    padding: calc(1rem + 5vw);
    overflow: hidden;
    margin-bottom: 10px;
    position: relative;
    display: flex;
  }
  .marquee p {
    font-size: 10rem;
    font-weight: 700;
    margin: 0;
    display: inline-block;
    white-space: nowrap;
    animation-name: marquee;
    animation-timing-function: linear;
    animation-duration: 30s;
    animation-iteration-count: infinite;
  }
  .marquee p:after {
    content: "";
    white-space: nowrap;
    padding-right: 40px;
  }
  @keyframes marquee {
    from {
      transform: translate(0%);
    }
    99%, to {
      transform: translate(-100%);
    }
  }
  .contents {
    width: 100%;
    padding: calc(1.5rem + 5vw) 2rem;
    margin-bottom: calc(1.5rem + 5vw);
  }
  .contents .works {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: -2%;
  }
  .contents .works .thumb {
    width: calc(100%);
    padding: 0 2%;
    word-wrap: break-word;
  }
  .contents .works .thumb h2 {
    font-weight: 600;
    width: auto;
    font-size: 2.4rem;
    padding: 30px 0;
    line-height: 1.5rem;
    border-top: 1px solid;
    border-bottom: 1px solid;
    border-color: white;
  }
  .contents .works .thumb figure {
    max-width: 100%;
    height: auto;
    margin: 0px 0;
    border-bottom: 1px solid;
    border-color: white;
  }
  .contents .works .thumb .credit {
    font-size: 1.2rem;
    letter-spacing: .04em;
    padding: 20px 0;
    border-bottom: 1px solid;
    border-color: rgba(255, 255, 255, 0.5);
  }
  .contents .works .thumb .credit:last-child {
    border-color: white;
  }
  footer {
    width: 100%;
  }
  footer .contact {
    border-top: solid 1px;
    border-bottom: solid 1px;
    border-color: rgba(255, 255, 255, 0.5);
    text-align: center;
    padding: calc(1.5rem + 15vw) 2rem;
    display: block;
    width: 100%;
    height: 100%;
  }
  footer .contact p {
    font-size: 4.5vw;
    margin-bottom: 2vw;
  }
  footer .contact h3 {
    font-size: 7.5vw;
  }
  footer .info {
    letter-spacing: .04em;
    padding: calc(1.5rem + 10vw) 2rem;
  }
  footer .info .info__wrapper .right {
    display: inline-block;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 10%;
  }
  footer .info .info__wrapper .right .logo {
    max-width: 150px;
    min-width: 100px;
    height: auto;
    margin-bottom: 30px;
  }
  footer .info .info__wrapper .right .sns {
    width: auto;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: flex-start;
    font-size: 1.4rem;
  }
  footer .info .info__wrapper .right .sns li {
    margin: 0 26px 20px 26px;
    position: relative;
    line-height: 1em;
  }
  footer .info .info__wrapper .right .sns li:last-child {
    margin: 0 0 0 26px;
  }
  footer .info .info__wrapper .right .sns .fb:before {
    content: "";
    display: inline-block;
    position: absolute;
    left: -22px;
    width: 15px;
    height: 15px;
    background: url(../img/fb.svg) no-repeat;
    background-size: contain;
    vertical-align: middle;
  }
  footer .info .info__wrapper .right .sns .tw:before {
    content: "";
    display: inline-block;
    position: absolute;
    left: -22px;
    width: 15px;
    height: 12px;
    background: url(../img/tw.svg) no-repeat;
    background-size: contain;
    vertical-align: middle;
  }
  footer .info .info__wrapper .right .sns .in:before {
    content: "";
    display: inline-block;
    position: absolute;
    left: -22px;
    width: 15px;
    height: 15px;
    background: url(../img/in.svg) no-repeat;
    background-size: contain;
    vertical-align: middle;
  }
  footer .info .info__wrapper .right .sns .spo:before {
    content: "";
    display: inline-block;
    position: absolute;
    left: -22px;
    width: 15px;
    height: 15px;
    background: url(../img/spo.svg) no-repeat;
    background-size: contain;
    vertical-align: middle;
  }
  footer .info .info__wrapper .left {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-end;
  }
  footer .info .info__wrapper .left p {
    font-size: 1.2rem;
    line-height: 1.4em;
    opacity: .8;
    margin-bottom: 30px;
  }
  footer .info .info__wrapper .left small {
    font-size: 1.2rem;
    line-height: 1.4em;
    opacity: .8;
  }
  .works .works__wrapper {
    margin-top: 6rem;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: flex-start;
  }
  .works .works__wrapper .img-sec {
    width: 100%;
  }
  .works .works__wrapper .info-sec {
    width: 100%;
    padding: 40px  0 0 0;
  }
  .works .works__wrapper .info-sec .title {
    letter-spacing: .04em;
    padding: 0px 0 20px 0;
    border-bottom: 1px solid;
    border-color: rgba(255, 255, 255, 0.5);
  }
  .works .works__wrapper .info-sec .title dt {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }
  .works .works__wrapper .info-sec .title dd {
    font-size: 3rem;
    font-weight: 700;
  }
  .works .works__wrapper .info-sec .credit {
    letter-spacing: .04em;
    padding: 20px 0;
    border-bottom: 1px solid;
    border-color: rgba(255, 255, 255, 0.5);
  }
  .works .works__wrapper .info-sec .credit dt {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }
  .works .works__wrapper .info-sec .credit dd {
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 2.5rem;
  }
  .works .works__wrapper .info-sec .credit .jp {
    font-size: 1.3rem;
    letter-spacing: .08em;
  }
  .about .about__wrapper {
    display: flex;
    flex-direction: column;
  }
  .about .about__wrapper .info-sec {
    width: 100%;
  }
  .about .about__wrapper .info-sec .credit {
    letter-spacing: .04em;
    padding: 20px 0;
    border-bottom: 1px solid;
    border-color: rgba(255, 255, 255, 0.5);
  }
  .about .about__wrapper .info-sec .credit:first-child {
    padding: 20px 0 20px 0;
  }
  .about .about__wrapper .info-sec .credit dt {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }
  .about .about__wrapper .info-sec .credit dd {
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 2.5rem;
  }
  .about .about__wrapper .info-sec .credit dd a {
    transition: all 1s ease;
  }
  .about .about__wrapper .info-sec .credit dd a:hover {
    filter: invert(100%);
  }
  .about .about__wrapper .info-sec .credit .jp {
    font-size: 1.4rem;
    letter-spacing: .08em;
  }
  .about .about__wrapper .title-sec {
    width: 100%;
  }
  .about .about__wrapper .title-sec .title {
    letter-spacing: .04em;
    border-bottom: 1px solid;
    border-color: rgba(255, 255, 255, 0.5);
    padding: 0px 0 20px 0;
  }
  .about .about__wrapper .title-sec .title dt {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }
  .about .about__wrapper .title-sec .title dd {
    font-size: 3rem;
    font-weight: 700;
  }
  .about .about__wrapper .title-sec .title dd a {
    transition: all 1s ease;
  }
  .about .about__wrapper .title-sec .title dd a:hover {
    filter: invert(100%);
  }
}

#loader-bg {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background: #19191b;
  z-index: 100000;
}

#loader {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  width: 60px;
  height: 60px;
  margin-top: -30px;
  margin-left: -30px;
  z-index: 2;
}

.pc_hover header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: var(--z-header);
}

.pc_hover header .header__wrapper {
  padding: calc(1.5rem + 3vw) 8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pc_hover header .header__wrapper .logo {
  width: 10%;
  max-width: 200px;
  min-width: 150px;
  height: auto;
  transition: all 0.8s ease;
}

.pc_hover header .header__wrapper .logo:hover {
  filter: invert(100%);
}

.pc_hover header .header__wrapper .menu {
  letter-spacing: .04em;
  font-size: 1.5rem;
  transition: all 0.8s ease;
}

.pc_hover header .header__wrapper .menu ul {
  display: flex;
}

.pc_hover header .header__wrapper .menu li {
  transition: all 0.8s ease;
  margin-left: 60px;
}

.pc_hover header .header__wrapper .menu li:hover {
  filter: invert(100%);
}

.pc_hover header .header__wrapper .menu li:hover:before {
  transition: all 0.8s ease;
}

.pc_hover header .header__wrapper .menu li:before {
  content: "";
  position: relative;
  top: -2px;
  left: -10px;
  display: inline-block;
  background-size: contain;
  vertical-align: middle;
  background-color: #fff;
  width: 6px;
  height: 6px;
  border-radius: 50%;
}

.pc_hover .marquee {
  margin-top: 12em;
  width: 100%;
  padding: calc(1rem + 5vw);
  overflow: hidden;
  margin-bottom: 10px;
  position: relative;
  display: flex;
}

.pc_hover .marquee p {
  font-size: 20rem;
  font-weight: 700;
  margin: 0;
  display: inline-block;
  white-space: nowrap;
  animation-name: marquee;
  animation-timing-function: linear;
  animation-duration: 30s;
  animation-iteration-count: infinite;
}

.pc_hover .marquee p:after {
  content: "";
  white-space: nowrap;
  padding-right: 40px;
}

@keyframes marquee {
  from {
    transform: translate(0%);
  }
  99%, to {
    transform: translate(-100%);
  }
}

.pc_hover .contents {
  width: 100%;
  padding: calc(1.5rem + 5vw) 8rem;
  margin-bottom: calc(1.5rem + 5vw);
}

.pc_hover .contents .works {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: -1%;
}

.pc_hover .contents .works .thumb {
  transition: all .8s ease;
  display: flex;
  width: calc(100% / 3);
  padding: 0 1%;
  word-wrap: break-word;
}

.pc_hover .contents .works .thumb:hover > a > .img-sec {
  filter: grayscale(1);
}

@media screen and (max-width: 1280px) {
  .pc_hover .contents .works .thumb {
    width: calc(100% / 2);
  }
}

.pc_hover .contents .works .thumb .title {
  font-weight: 600;
  width: auto;
  font-size: 2.2rem;
  padding: 20px 0;
  line-height: 3rem;
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-color: white;
  vertical-align: middle;
}

.pc_hover .contents .works .thumb .credit {
  line-height: 1.4;
  height: auto;
  font-size: 1.4rem;
  letter-spacing: .04em;
  padding: 20px 0;
  border-bottom: 1px solid;
  border-color: rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
}

.pc_hover .contents .works .thumb .credit:last-child {
  border-color: white;
}

.pc_hover .contents .works .thumb figure {
  transition: all .5s ease;
  width: 100%;
  height: auto;
  margin: 0px 0;
  border-bottom: 1px solid;
  border-color: white;
}

.pc_hover footer {
  width: 100%;
}

.pc_hover footer .contact {
  border-top: solid 1px;
  border-bottom: solid 1px;
  border-color: rgba(255, 255, 255, 0.5);
  text-align: center;
  padding: calc(1.5rem + 10vw) 5rem;
  display: block;
  width: 100%;
  height: 100%;
}

.pc_hover footer .contact .contact__wrapper {
  transition: all 1s ease;
}

.pc_hover footer .contact .contact__wrapper p {
  font-size: 1.5vw;
  margin-bottom: 1vw;
}

.pc_hover footer .contact .contact__wrapper h3 {
  transition: all .8s ease;
  font-size: 6vw;
}

.pc_hover footer .contact:hover > .contact__wrapper {
  filter: invert(100%);
}

.pc_hover footer .contact:hover > .contact__wrapper h3 {
  letter-spacing: .2rem;
}

.pc_hover footer .info {
  letter-spacing: .04em;
  padding: calc(1.5rem + 5vw) 8rem;
}

.pc_hover footer .info .info__wrapper .right {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 2%;
}

.pc_hover footer .info .info__wrapper .right .logo {
  transition: all 0.8s ease;
  max-width: 150px;
  min-width: 100px;
  height: auto;
}

.pc_hover footer .info .info__wrapper .right .logo:hover {
  filter: invert(100%);
}

.pc_hover footer .info .info__wrapper .right .sns {
  width: auto;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  align-items: flex-start;
  font-size: 1.4rem;
  transition: all 1s ease;
}

.pc_hover footer .info .info__wrapper .right .sns li {
  margin: 0 40px;
  transition: all .8s ease;
  position: relative;
  line-height: 1em;
}

.pc_hover footer .info .info__wrapper .right .sns li:last-child {
  margin: 0 0 0 40px;
}

.pc_hover footer .info .info__wrapper .right .sns li:hover {
  filter: invert(100%);
}

.pc_hover footer .info .info__wrapper .right .sns .fb:before {
  content: "";
  display: inline-block;
  position: absolute;
  left: -22px;
  width: 15px;
  height: 15px;
  background: url(../img/fb.svg) no-repeat;
  background-size: contain;
  vertical-align: middle;
}

.pc_hover footer .info .info__wrapper .right .sns .tw:before {
  content: "";
  display: inline-block;
  position: absolute;
  left: -22px;
  width: 15px;
  height: 12px;
  background: url(../img/tw.svg) no-repeat;
  background-size: contain;
  vertical-align: middle;
}

.pc_hover footer .info .info__wrapper .right .sns .in:before {
  content: "";
  display: inline-block;
  position: absolute;
  left: -22px;
  width: 15px;
  height: 15px;
  background: url(../img/in.svg) no-repeat;
  background-size: contain;
  vertical-align: middle;
}

.pc_hover footer .info .info__wrapper .right .sns .spo:before {
  content: "";
  display: inline-block;
  position: absolute;
  left: -22px;
  width: 15px;
  height: 15px;
  background: url(../img/spo.svg) no-repeat;
  background-size: contain;
  vertical-align: middle;
}

.pc_hover footer .info .info__wrapper .left {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-end;
}

.pc_hover footer .info .info__wrapper .left p {
  font-size: 1.2rem;
  line-height: 1.4em;
  opacity: .8;
}

.pc_hover footer .info .info__wrapper .left small {
  font-size: 1.2rem;
  line-height: 1.4em;
  opacity: .8;
}

.works .works__wrapper {
  margin-top: 10rem;
  display: flex;
}

.works .works__wrapper .img-sec {
  width: 65%;
}

.works .works__wrapper .img-sec figure {
  margin-bottom: 2px;
}

.works .works__wrapper .info-sec {
  width: 35%;
  padding: 0  0 0 5rem;
}

.works .works__wrapper .info-sec .title {
  letter-spacing: .04em;
  padding: 0px 0 20px 0;
  border-bottom: 1px solid;
  border-color: rgba(255, 255, 255, 0.5);
}

.works .works__wrapper .info-sec .title dt {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.works .works__wrapper .info-sec .title dd {
  font-size: 3rem;
  font-weight: 700;
}

.works .works__wrapper .info-sec .title dd a {
  transition: all 1s ease;
}

.works .works__wrapper .info-sec .title dd a:hover {
  filter: invert(100%);
}

.works .works__wrapper .info-sec .credit {
  letter-spacing: .04em;
  padding: 20px 0;
  border-bottom: 1px solid;
  border-color: rgba(255, 255, 255, 0.5);
}

.works .works__wrapper .info-sec .credit dt {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.works .works__wrapper .info-sec .credit dd {
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 2.5rem;
}

.works .works__wrapper .info-sec .credit dd a {
  transition: all 1s ease;
}

.works .works__wrapper .info-sec .credit dd a:hover {
  filter: invert(100%);
}

.works .works__wrapper .info-sec .credit .jp {
  font-size: 1.3rem;
  letter-spacing: .08em;
}
