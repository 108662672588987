/*----------------------------------------------------
  smp
----------------------------------------------------*/

@media screen and ( $smp )
{
  header{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: var(--z-header);
    .header__wrapper{
      padding: calc(1rem + 5vw) 2rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .logo{
        width: 38%;
        max-width: 300px;
        min-width: 100px;
        height: auto;
      }
      .menu{
        letter-spacing: .04em;
        font-size: 1.4rem;
        transition: all 0.8s ease;
        ul{
          display: flex;
        }
        li{
          margin-left: 40px;
          &:before{
            content: "";
            position: relative;
            top: -1px;
            left: -10px;
            display:inline-block;
            background-size:contain;
            vertical-align: middle;
            background-color: #fff;
            width: 5px;
            height: 5px;
            border-radius: 50%;
          }
        }
      }
    }
  }

  .marquee{
    margin-top: 6em;
    width:100%;
    padding: calc(1rem + 5vw);
    overflow:hidden;
    margin-bottom:10px;
    position:relative;
    display: flex;
    p{
      font-size: 10rem;
      font-weight: 700;
      margin:0;
      display:inline-block;
      white-space:nowrap;
      animation-name:marquee;
      animation-timing-function:linear;
      animation-duration:30s;
      animation-iteration-count:infinite;
      &:after {
        content:"";
        white-space:nowrap;
        padding-right:40px;
      }
    }
    @keyframes marquee {
    from   { transform: translate(0%);}
    99%,to { transform: translate(-100%);}
    }
  }

  .contents{
    width: 100%;
    padding: calc(1.5rem + 5vw) 2rem;
    margin-bottom: calc(1.5rem + 5vw);
    .works{
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin: 0 - 2%;
      .thumb{
        width: calc(100%);
        padding: 0 2%;
        word-wrap: break-word;
        h2{
          font-weight: 600;
          width: auto;
          font-size: 2.4rem;
          padding: 30px 0;
          line-height: 1.5rem;
          border-top: 1px solid;
          border-bottom: 1px solid;
          border-color: rgba(255, 255, 255, 1);
        }
        figure{
          max-width:100%;
          height: auto;
          margin: 0px 0;
          border-bottom: 1px solid;
          border-color: rgba(255, 255, 255, 1);
        }
        .credit{
          font-size: 1.2rem;
          letter-spacing: .04em;
          padding: 20px 0;
          border-bottom: 1px solid;
          border-color: rgba(255, 255, 255, .5);
          &:last-child{
            border-color: rgba(255, 255, 255, 1);
          }
        }
      }
    }
  }


  footer{
    width: 100%;
    .contact{
      border-top: solid 1px;
      border-bottom: solid 1px;
      border-color: rgba(255, 255, 255, 0.5);
      text-align: center;
      padding: calc(1.5rem + 15vw) 2rem;
      display: block;
      width: 100%;
      height: 100%;
      p{
        font-size: 4.5vw;
        margin-bottom: 2vw;
      }
      h3{
        font-size: 7.5vw;
      }
    }
    .info{
      letter-spacing: .04em;
      padding: calc(1.5rem + 10vw) 2rem;
      .info__wrapper{
        .right{
          display: inline-block;
          justify-content: flex-start;
          align-items: center;
          flex-wrap: wrap;
          margin-bottom: 10%;
          .logo{
            max-width: 150px;
            min-width: 100px;
            height: auto;
            margin-bottom: 30px;
          }
          .sns{
            width: auto;
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            align-items: flex-start;
            font-size: 1.4rem;
            li{
              margin: 0 26px 20px 26px;
              &:last-child{
                margin: 0 0 0 26px;
              }
              position:relative;
              line-height:1em;
            }
            .fb{
              &:before {
                content: "";
                display:inline-block;
                position:absolute;
                left: -22px;
                width: 15px;
                height: 15px;
                background:url(../img/fb.svg) no-repeat;
                background-size:contain;
                vertical-align: middle;
              }
            }
            .tw{
              &:before {
                content: "";
                display:inline-block;
                position:absolute;
                left: -22px;
                width: 15px;
                height: 12px;
                background:url(../img/tw.svg) no-repeat;
                background-size:contain;
                vertical-align: middle;
              }
            }
            .in{
              &:before {
                content: "";
                display:inline-block;
                position:absolute;
                left: -22px;
                width: 15px;
                height: 15px;
                background:url(../img/in.svg) no-repeat;
                background-size:contain;
                vertical-align: middle;
              }
            }
            .spo{
              &:before {
                content: "";
                display:inline-block;
                position:absolute;
                left: -22px;
                width: 15px;
                height: 15px;
                background:url(../img/spo.svg) no-repeat;
                background-size:contain;
                vertical-align: middle;
              }
            }
          }
        }
        .left{
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          align-items: flex-end;
          p{
            font-size: 1.2rem;
            line-height: 1.4em;
            opacity: .8;
            margin-bottom: 30px;
          }
          small{
            font-size: 1.2rem;
            line-height: 1.4em;
            opacity: .8;
          }
        }
      }
    }
  }

  .works{
    .works__wrapper{
      margin-top: 6rem;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      align-items: flex-start;
      .img-sec{
        width: 100%;
      }
      .info-sec{
        width: 100%;
        padding: 40px  0 0 0;
        .title{
          letter-spacing: .04em;
          padding: 0px 0 20px 0;
          border-bottom: 1px solid;
          border-color: rgba(255, 255, 255, 0.5);
          dt{
            font-size: 1.2rem;
            margin-bottom: 10px;
          }
          dd{
            font-size: 3rem;
            font-weight: 700;
          }
        }
        .credit{
          letter-spacing: .04em;
          padding: 20px 0;
          border-bottom: 1px solid;
          border-color: rgba(255, 255, 255, 0.5);
          dt{
            font-size: 1.2rem;
            margin-bottom: 10px;
          }
          dd{
            font-weight: 700;
            font-size: 1.6rem;
            line-height: 2.5rem;
          }
          .jp{
            font-size: 1.3rem;
            letter-spacing: .08em;
          }
        }
      }
    }
  }

  .about{
    .about__wrapper{
      display: flex;
      flex-direction: column;
      .info-sec{
        width: 100%;
        .credit{
          letter-spacing: .04em;
          padding: 20px 0;
          border-bottom: 1px solid;
          border-color: rgba(255, 255, 255, 0.5);
          &:first-child{
            padding: 20px 0 20px 0;
          }
          dt{
            font-size: 1.2rem;
            margin-bottom: 10px;
          }
          dd{
            font-weight: 600;
            font-size: 1.6rem;
            line-height: 2.5rem;
            a{
              transition: all 1s ease;
              &:hover{
                filter: invert(100%);
              }
            }
          }
          .jp{
            font-size: 1.4rem;
            letter-spacing: .08em;
          }
        }
      }
      .title-sec{
        width: 100%;
        .title{
          letter-spacing: .04em;
          border-bottom: 1px solid;
          border-color: rgba(255, 255, 255, 0.5);
          padding: 0px 0 20px 0;
          dt{
            font-size: 1.2rem;
            margin-bottom: 10px;
          }
          dd{
            font-size: 3rem;
            font-weight: 700;
            a{
              transition: all 1s ease;
              &:hover{
                filter: invert(100%);
              }
            }
          }
        }
      }
    }
  }



}//@media
