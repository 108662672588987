#loader-bg {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background: #19191b;
  z-index: 100000;
}
#loader {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  width: 60px;
  height: 60px;
  margin-top: -30px;
  margin-left: -30px;
  z-index: 2;
}

// .about{
//   position: fixed;
//   display: inline-block;
//   bottom: 30px;
//   right: 30px;
//   font-size: 1.2rem;
//   background-color: #fff;
//   border-radius: 50%;
//   width: 60px;
//   height: 60px;
//   text-align:center;
//   line-height: 60px;
//   z-index: var(--z-header);
//   transition: all 0.8s ease;
//   &:hover{
//     filter: invert(100%);
//     transform:scale(1.2);
//     transform:rotate(-10deg);
//   }
//   a{
//     display: block;
//     p{
//       color: rgb(46, 46, 46);
//     }
//   }
// }

.pc_hover{
  header{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: var(--z-header);
    .header__wrapper{
      padding: calc(1.5rem + 3vw) 8rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .logo{
        width: 10%;
        max-width: 200px;
        min-width: 150px;
        height: auto;
        transition: all 0.8s ease;
        &:hover{
          filter: invert(100%);
        }
      }
      .menu{
        letter-spacing: .04em;
        font-size: 1.5rem;
        transition: all 0.8s ease;
        ul{
          display: flex;
        }
        li{
          transition: all 0.8s ease;
          margin-left: 60px;
          &:hover{
            filter: invert(100%);
            &:before{
              transition: all 0.8s ease;
            }
          }
          &:before{
            content: "";
            position: relative;
            top: -2px;
            left: -10px;
            display:inline-block;
            background-size:contain;
            vertical-align: middle;
            background-color: #fff;
            width: 6px;
            height: 6px;
            border-radius: 50%;
          }
        }
      }
    }
  }

  .marquee{
    margin-top: 12em;
    width:100%;
    padding: calc(1rem + 5vw);
    overflow:hidden;
    margin-bottom:10px;
    position:relative;
    display: flex;
    p{
      font-size: 20rem;
      font-weight: 700;
      margin:0;
      display:inline-block;
      white-space:nowrap;
      animation-name:marquee;
      animation-timing-function:linear;
      animation-duration:30s;
      animation-iteration-count:infinite;
      &:after {
        content:"";
        white-space:nowrap;
        padding-right:40px;
      }
    }
    @keyframes marquee {
    from   { transform: translate(0%);}
    99%,to { transform: translate(-100%);}
    }
  }

  .contents{
    width: 100%;
    padding: calc(1.5rem + 5vw) 8rem;
    margin-bottom: calc(1.5rem + 5vw);
    .works{
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin: 0 - 1%;
      .thumb{
        transition: all .8s ease;
        display: flex;
         &:hover > a > .img-sec{
          filter: grayscale(1);
        }
        width: calc(100% / 3);
        @media screen and ( $pc-small )
        {
          width: calc(100% / 2);
        }
        padding: 0 1%;
        word-wrap: break-word;
        .title{
          font-weight: 600;
          width: auto;
          // height: 100%;
          font-size: 2.2rem;
          padding: 20px 0;
          line-height: 3rem;
          border-top: 1px solid;
          border-bottom: 1px solid;
          border-color: rgba(255, 255, 255, 1);
          vertical-align: middle;
        }
        .credit{
          line-height: 1.4;
          height: auto;
          font-size: 1.4rem;
          letter-spacing: .04em;
          padding: 20px 0;
          border-bottom: 1px solid;
          border-color: rgba(255, 255, 255, .5s);
          box-sizing: border-box;
          &:last-child{
            border-color: rgba(255, 255, 255, 1s);
          }
        }
        figure{
          transition: all .5s ease;
          width:100%;
          height: auto;
          margin: 0px 0;
          border-bottom: 1px solid;
          border-color: rgb(255, 255, 255);
        }
      }
    }
  }


  footer{
    width: 100%;
    .contact{
      border-top: solid 1px;
      border-bottom: solid 1px;
      border-color: rgba(255, 255, 255, 0.5);
      text-align: center;
      padding: calc(1.5rem + 10vw) 5rem;
      display: block;
      width: 100%;
      height: 100%;
      .contact__wrapper{
        transition: all 1s ease;
        p{
          font-size: 1.5vw;
          margin-bottom: 1vw;
        }
        h3{
          transition: all .8s ease;
          font-size: 6vw;
        }
      }
      &:hover > .contact__wrapper{
        filter: invert(100%);
        h3{
          letter-spacing: .2rem;
        }
      }
    }
    .info{
      letter-spacing: .04em;
      padding: calc(1.5rem + 5vw) 8rem;
      .info__wrapper{
        .right{
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-wrap: wrap;
          margin-bottom: 2%;
          .logo{
            transition: all 0.8s ease;
            &:hover{
              filter: invert(100%);
            }
            max-width: 150px;
            min-width: 100px;
            height: auto;
          }
          .sns{
            width: auto;
            display: flex;
            justify-content: flex-end;
            flex-wrap: wrap;
            align-items: flex-start;
            font-size: 1.4rem;
            transition: all 1s ease;
            li{
              margin: 0 40px;
              transition: all .8s ease;
              position:relative;
              line-height:1em;
              &:last-child{
                margin: 0 0 0 40px;
              }
              &:hover{
                filter: invert(100%);
              }
            }
            .fb{
              &:before {
                content: "";
                display:inline-block;
                position:absolute;
                left: -22px;
                width: 15px;
                height: 15px;
                background:url(../img/fb.svg) no-repeat;
                background-size:contain;
                vertical-align: middle;
              }
            }
            .tw{
              &:before {
                content: "";
                display:inline-block;
                position:absolute;
                left: -22px;
                width: 15px;
                height: 12px;
                background:url(../img/tw.svg) no-repeat;
                background-size:contain;
                vertical-align: middle;
              }
            }
            .in{
              &:before {
                content: "";
                display:inline-block;
                position:absolute;
                left: -22px;
                width: 15px;
                height: 15px;
                background:url(../img/in.svg) no-repeat;
                background-size:contain;
                vertical-align: middle;
              }
            }
            .spo{
              &:before {
                content: "";
                display:inline-block;
                position:absolute;
                left: -22px;
                width: 15px;
                height: 15px;
                background:url(../img/spo.svg) no-repeat;
                background-size:contain;
                vertical-align: middle;
              }
            }
          }
        }
        .left{
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          align-items: flex-end;
          p{
            font-size: 1.2rem;
            line-height: 1.4em;
            opacity: .8;
          }
          small{
            font-size: 1.2rem;
            line-height: 1.4em;
            opacity: .8;
          }
        }
      }


    }
  }
}
